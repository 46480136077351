var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex"
  }, [_c("div", {
    staticClass: "avatur",
    class: _vm.item.userinfodto.certification ? "border" : ""
  }, [_c("a-avatar", {
    attrs: {
      src: _vm.item.userinfodto.headimgurl,
      size: 48
    }
  })], 1), _c("div", {
    staticClass: "wrapper"
  }, [_c("div", {
    staticClass: "user-info"
  }, [_c("p", [_vm._v(" " + _vm._s(_vm.item.userinfodto.username) + " ")]), _c("p", [_vm._v(_vm._s(_vm.item.time))])]), _vm._t("default")], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };