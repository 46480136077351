export default {
  data: function data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  mounted: function mounted() {
    console.log(this.item);
  }
};