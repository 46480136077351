var columns = [{
  title: '内容id',
  dataIndex: 'dynamicid',
  key: 'dynamicid',
  width: 180,
  scopedSlots: {
    customRender: 'dynamicid'
  },
  align: 'center'
}, {
  title: '用户名',
  dataIndex: 'username',
  key: 'username',
  width: 120,
  scopedSlots: {
    customRender: 'username'
  },
  align: 'center'
}, {
  title: '类型',
  dataIndex: 'cate',
  key: 'cate',
  width: 100,
  scopedSlots: {
    customRender: 'cate'
  },
  align: 'center'
}, {
  title: '子类型',
  dataIndex: 'type',
  key: 'type',
  width: 100,
  scopedSlots: {
    customRender: 'type'
  },
  align: 'center'
}, {
  title: '原创类型',
  dataIndex: 'releasetype',
  key: 'releasetype',
  width: 100,
  scopedSlots: {
    customRender: 'releasetype'
  },
  align: 'center'
}, {
  title: '发布渠道',
  dataIndex: 'source',
  key: 'source',
  width: 100,
  scopedSlots: {
    customRender: 'source'
  },
  align: 'center'
}, {
  title: '是否同步朋友圈',
  dataIndex: 'syncmoments',
  key: 'syncmoments',
  width: 100,
  scopedSlots: {
    customRender: 'syncmoments'
  },
  align: 'center'
}, {
  title: '可见权限',
  dataIndex: 'openflag',
  key: 'openflag',
  width: 100,
  scopedSlots: {
    customRender: 'openflag'
  },
  align: 'center'
}, {
  title: '发布时间(UTC)',
  dataIndex: 'createdata',
  key: 'createdata',
  width: 140,
  scopedSlots: {
    customRender: 'createdata'
  },
  align: 'center'
}, {
  title: '更改时间(UTC)',
  dataIndex: 'updatedata',
  key: 'updatedata',
  width: 140,
  scopedSlots: {
    customRender: 'updatedata'
  },
  align: 'center'
}, {
  title: '审核时间(UTC)',
  dataIndex: 'auditdata',
  key: 'auditdata',
  width: 140,
  scopedSlots: {
    customRender: 'auditdata'
  },
  align: 'center'
}, {
  title: '审核人',
  dataIndex: 'auditname',
  key: 'auditname',
  width: 100,
  scopedSlots: {
    customRender: 'auditname'
  },
  align: 'center'
}, {
  title: '状态',
  dataIndex: 'auditstatus',
  key: 'auditstatus',
  width: 100,
  scopedSlots: {
    customRender: 'auditstatus'
  },
  align: 'center'
}, {
  title: '操作',
  key: 'actions',
  dataIndex: 'actions',
  fixed: 'right',
  width: 240,
  scopedSlots: {
    customRender: 'actions'
  },
  align: 'center'
}];
export { columns };